import { Box, Typography } from "@mui/material";
import { ThemeProvider } from "@mui/styles";
import { styled } from "@mui/system";
import { Token } from "~/api/data-schema";
import { useScreenOptions } from "~/features/screen";
import { H1, H2 } from "~/features/typography";
import ICAtheme, { ICAcolors } from "../theme";
import { AboutArtist } from "./AboutArtist/AboutArtist";
import { Footer } from "./Footer/Footer";
import { Hero } from "./Hero/Hero";
import { StayConnected } from "./StayConnected/StayConnected";
import Effect from "./effect.svg";
import Effect2 from "./effect2.svg";
import { AboutWork } from "./AboutWork";

const Container = styled(Box)({
    backgroundColor: "#F1E3E1",
    width: "100%",
    position: "relative",
    paddingTop: "4rem",
});
const EffectBox = styled(Box)({
    width: "100%",
    position: "absolute",
    left: 0,
    top: 0,
    zIndex: 0,
});
const Effect2Box = styled(Box)({
    position: "absolute",
    right: 0,
    top: 70,
    zIndex: 0,
});
const Main = styled(Box)({
    paddingBottom: "1rem",
    overflowX: "hidden",
    maxWidth: "1920px",
    margin: "auto",
});
const OverflowContainer = styled(Box)({
    display: "flex",
    width: "120%",
    marginLeft: "-3rem",
    marginTop: "5rem",
    overflow: "hidden",
});
const Text = styled(Box)(({ theme }) => ({
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "9rem",
    display: "flex",
    justifyContent: "center",
    width: "80%",
    [theme.breakpoints.down("sm")]: {
        marginTop: "4rem",
    },
    [theme.breakpoints.down("xs")]: {
        width: "90%",
    },
}));

interface HomepageProps {
    tokens: Token[];
    auctionEnd: Date;
}
export function Homepage({ tokens, auctionEnd }: HomepageProps) {
    const { isMobile } = useScreenOptions();
    return (
        <ThemeProvider theme={ICAtheme}>
            <Container>
                <EffectBox>
                    <Effect width="100%" />
                </EffectBox>
                <Effect2Box>
                    <Effect2 />
                </Effect2Box>
                <Main>
                    <Hero tokens={tokens} auctionEnd={auctionEnd} />
                    <AboutWork />
                    <OverflowContainer>
                        {[...Array(20)].map(() => (
                            <>
                                {isMobile ? (
                                    <Box mr="1.5rem">
                                        <H2 color={ICAcolors.primaryLight}>
                                            Program
                                        </H2>
                                    </Box>
                                ) : (
                                    <Box mr="3rem">
                                        <H1 color={ICAcolors.primaryLight}>
                                            Program
                                        </H1>
                                    </Box>
                                )}
                            </>
                        ))}
                    </OverflowContainer>
                    <Text>
                        <Typography variant="h3" color="secondary">
                            {`ICA Miami is thrilled to announce an exclusive NFT
                            Auction in partnership with LiveArtX and Palm NFT.
                            The auction features work by artist Cory Van Lew and
                            supports the museum’s ongoing mission to provide
                            free and open access to art, ideas, and innovation`}
                        </Typography>
                    </Text>
                    <AboutArtist />
                    <StayConnected />
                    <Footer />
                </Main>
            </Container>
        </ThemeProvider>
    );
}
