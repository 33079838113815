import { styled } from "@mui/system";
import Skeleton from "@mui/material/Skeleton";

const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
    skeleton: {
        backgroundColor: theme.palette.primary.main,
        width: 350,
        height: 500,
        transform: "none",
        [theme.breakpoints.down("md")]: {
            width: 307,
            height: 400,
        },
        [theme.breakpoints.down("sm")]: {
            width: 258,
            height: 432,
        },
    },
}));

export function ArtworkCardSkeleton() {
    return <StyledSkeleton />;
}
