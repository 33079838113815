import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";

const Container = styled(Box)({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "80%",
    margin: "auto",
    marginTop: "5rem",
});
const Bullets = styled(Box)({
    marginTop: "1rem",
    "& > *": {
        marginBottom: "0.4rem",
        alignItems: "center",
        display: "flex",
        gap: "10px",
    },
});
const Bullet = styled(Box)(({ theme }) => ({
    width: 10,
    height: 10,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "50%",
}));

export function AboutWork() {
    return (
        <Container>
            <Typography variant="h4" color="primary">
                About the Work
            </Typography>
            <Box mt="1rem">
                <Typography variant="h5" color="secondary">
                    {`This NFT includes a physical painting created on-site during
                    the ICA Miami's 365 Gala on April 9, 2022.`}
                </Typography>
            </Box>
            <Box mt="1rem">
                <Typography variant="h5" color="secondary">
                    Details for Paintings:
                </Typography>
            </Box>
            <Bullets>
                <Typography variant="h5" color="secondary">
                    <Bullet /> Cory Van Lew, Alpha, 2022. Acrylic on canvas, 6 x
                    4 ft.
                </Typography>

                <Typography variant="h5" color="secondary">
                    <Bullet /> Cory Van Lew, Beta, 2022. Acrylic on canvas, 6 x
                    4 ft.
                </Typography>

                <Typography variant="h5" color="secondary">
                    <Bullet /> Cory Van Lew, Gamma, 2022. Acrylic on canvas, 6 x
                    4 ft.
                </Typography>
            </Bullets>
        </Container>
    );
}
