import { Box, Typography } from "@mui/material";
import Link from "next/link";
import { useMemo } from "react";
import { styled } from "@mui/system";
import { Token } from "~/api/data-schema";
import { useCountdown } from "~/features/countdown";
import { useScreenOptions } from "~/features/screen";
import { BodyM } from "~/features/typography";
import { ICAcolors } from "../../theme";
import { ArtworkCard } from "../ArtworkCard/ArtworkCard";
import { ArtworkCardSkeleton } from "../ArtworkCard/ArtworkCardSekeleton";
import { Countdown } from "../Countdown";
import BookIcon from "./book.svg";

const Container = styled(Box)({
    "& *": {
        zIndex: 1,
    },
});
const Title = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& *": {
        zIndex: 1,
    },
    [theme.breakpoints.down("sm")]: {
        textAlign: "center",
    },
}));
const Header = styled(Box)(({ theme }) => ({
    [theme.breakpoints.down("sm")]: {
        marginBottom: "2.5rem",
    },
}));
const Subtitle = styled(Box)({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "1rem",
    textAlign: "center",
});
const HelpText = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    margin: "auto",
    marginTop: "5rem",
    maxWidth: "1200px",
    marginBottom: "1rem",
    [theme.breakpoints.down("md")]: {
        marginLeft: "2.8rem",
        marginTop: "3rem",
    },
    [theme.breakpoints.down("xs")]: {
        marginLeft: "0",
        marginTop: "1rem",
    },
}));
const LinkSpan = styled("span")({
    textDecoration: "underline",
    textUnderlineOffset: "3px",
});
const Tokens = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    marginTop: "4rem",
    overflowX: "auto",
    paddingBottom: "1rem",
    [theme.breakpoints.down(1080)]: {
        justifyContent: "flex-start",
        marginLeft: "0.5rem",
        marginTop: "0",
    },
}));

interface HeroProps {
    tokens: Token[];
    auctionEnd: Date;
}
export function Hero({ tokens, auctionEnd }: HeroProps) {
    const countdown = useCountdown({
        endTime: auctionEnd,
    });
    const { isMobile } = useScreenOptions();

    const tokenSection = useMemo(
        () => (
            <>
                {tokens.length === 0 ? (
                    <>
                        {[1, 2, 3].map((key) => (
                            <Box key={key} mr="3rem">
                                <ArtworkCardSkeleton />
                            </Box>
                        ))}
                    </>
                ) : (
                    <>
                        {tokens.map(
                            (token) =>
                                token?.tokenId && (
                                    <Box key={token?.tokenId} mr="3rem">
                                        <ArtworkCard token={token} />
                                    </Box>
                                ),
                        )}
                    </>
                )}
            </>
        ),
        [tokens],
    );

    return (
        <Box position="relative">
            <Container>
                <Title>
                    <Header>
                        <Typography variant="h5" color="secondary">
                            Institute of Contemporary Art, Miami
                        </Typography>
                    </Header>
                    <Typography variant="h1" color="primary">
                        {isMobile ? (
                            <span>
                                Cory <br /> Van Lew
                            </span>
                        ) : (
                            <span>CORY VAN LEW</span>
                        )}
                    </Typography>
                </Title>
                <Subtitle>
                    <Typography variant="h5" color="secondary">
                        Limited Edition NFTs for the 365 Party
                    </Typography>
                </Subtitle>
                <Box mt="1rem" display="flex" justifyContent="center">
                    {+auctionEnd > Date.now() && <Countdown {...countdown} />}
                </Box>
                <HelpText>
                    <Box>
                        <BookIcon />
                    </Box>
                    <BodyM color={ICAcolors.secondary}>
                        <LinkSpan>
                            <Link href="https://365.icamiami.org/bidding-guide">
                                HOW TO PLACE A BID?
                            </Link>
                        </LinkSpan>
                    </BodyM>
                </HelpText>
                <Tokens>{tokenSection}</Tokens>
            </Container>
        </Box>
    );
}
