import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { fontFamilies } from "~/theme";

export const ICAcolors = {
    primary: "#FF5893",
    secondary: "#6E62C3",
    textPrimary: "#FFF",
    primaryLight: "#FF96BB",
};

const ICAtheme = createTheme({
    palette: {
        primary: {
            main: ICAcolors.primary,
            light: "#FF96BB",
        },
        secondary: {
            main: ICAcolors.secondary,
        },
        text: {
            primary: ICAcolors.textPrimary,
        },
    },
    typography: {
        fontFamily: `Forum,${fontFamilies.hurme2}`,
        h5: {
            fontSize: "1.375rem",
        },
        h1: {
            fontSize: "8.125rem",
        },
    },

    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 9999,
                },
            },
        },
    },
});

export default responsiveFontSizes(ICAtheme);
