import { Box, Button, Typography } from "@mui/material";
import { ArrowForward as ArrowForwardIcon } from "@mui/icons-material";
import { styled } from "@mui/system";
import { BodyM } from "~/features/typography";
import FooterBackground from "./footerBackground.png";
import { useScreenOptions } from "~/features/screen";
import Logo from "./logo.svg";
import { ICAcolors } from "../../theme";
import DiscordICA from "~/features/icon/discordICA.svg";
import TwitterICA from "~/features/icon/twitterICA.svg";
import InstaICA from "~/features/icon/instagramICA.svg";

const Container = styled(Box)(({ theme }) => ({
    backgroundImage: `url(${FooterBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "95%",
    margin: "auto",
    marginTop: "230px",
    paddingTop: "113px",
    [theme.breakpoints.down(1281)]: {
        marginTop: "80px",
    },
}));
const Title = styled(Box)(({ theme }) => ({
    textAlign: "center",
    [theme.breakpoints.down(376)]: {
        marginLeft: "0px",
    },
}));
const Info = styled(Box)(({ theme }) => ({
    marginTop: "4rem",
    display: "flex",
    width: "70%",
    margin: "auto",
    justifyContent: "space-evenly",
    [theme.breakpoints.down(1281)]: {
        width: "90%",
    },
    [theme.breakpoints.down(376)]: {
        flexDirection: "column",
        alignItems: "center",
    },
}));
const Column = styled(Box)(({ theme }) => ({
    textAlign: "center",
    width: 200,
    [theme.breakpoints.down(376)]: {
        marginBottom: "3rem",
    },
}));
const ButtonContainer = styled(Box)(({ theme }) => ({
    marginTop: "4rem",
    width: "fit-content",
    [theme.breakpoints.down(376)]: {
        marginTop: "1rem",
        width: "90%",
    },
}));
const SocialWrap = styled(Box)(({ theme }) => ({
    marginTop: "20px",
    textAlign: "center",
    [theme.breakpoints.down(376)]: {
        marginTop: "20px",
        marginLeft: "20px",
        textAlign: "left",
    },
}));
const SocialButton = styled("a")({
    margin: "0 12px",
});
const Dashed = styled(Box)(({ theme }) => ({
    borderBottom: "2px dashed white",
    marginTop: "170px",
    marginBottom: "40px",
    width: "90%",
    [theme.breakpoints.down(1281)]: {
        marginTop: "100px",
        marginBottom: "16px",
    },
}));
const BottomText = styled(Box)(({ theme }) => ({
    margin: "auto",
    marginTop: "3rem",
    marginBottom: "3rem",
    width: "50%",
    textAlign: "center",
    [theme.breakpoints.down(1281)]: {
        width: "90%",
    },
}));

export function Footer() {
    const { isMobile } = useScreenOptions();
    return (
        <>
            <Container>
                <Title>
                    <Typography color="textPrimary" variant="h1">
                        Contact
                    </Typography>
                </Title>
                <Info>
                    <Column>
                        <Typography variant="h5" color="textPrimary">
                            Phone
                        </Typography>
                        <Typography variant="h5" color="textPrimary">
                            305 901 5272
                        </Typography>
                    </Column>
                    <Column>
                        <Typography variant="h5" color="textPrimary">
                            Address
                        </Typography>
                        <Typography variant="h5" color="textPrimary">
                            61 NE 41st Street Miami, <br /> FL 33137
                        </Typography>
                    </Column>
                    <Column>
                        <Typography variant="h5" color="textPrimary">
                            Email
                        </Typography>
                        <Typography variant="h5" color="textPrimary">
                            365party@icamiami.org
                        </Typography>
                    </Column>
                </Info>
                <ButtonContainer>
                    <Button
                        href="mailto:365party@icamiami.org"
                        fullWidth={isMobile}
                        size="large"
                        color="primary"
                        variant="contained"
                        endIcon={<ArrowForwardIcon htmlColor="white" />}
                    >
                        <BodyM color="white">GET IN TOUCH</BodyM>
                    </Button>
                </ButtonContainer>
                <SocialWrap>
                    <SocialButton
                        href="https://discord.com/invite/3Jn7vSHXbZ"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <DiscordICA />
                    </SocialButton>
                    <SocialButton
                        href="https://twitter.com/PalmNft"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <TwitterICA />
                    </SocialButton>

                    <SocialButton
                        href="https://www.instagram.com/palmnft/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <InstaICA />
                    </SocialButton>
                </SocialWrap>
                <Dashed />
                <BottomText>
                    <Typography variant="h5" color="textPrimary">
                        All contributions in excess of the fair market value of
                        the evening ($200 per person for cocktails and dinner)
                        are tax-deductible within the limits prescribed by law
                    </Typography>
                </BottomText>
            </Container>
            <Box mt="2rem" mb="1.5rem" display="flex" justifyContent="center">
                <Box mr="1rem">
                    <Logo />
                </Box>
                <Box>
                    <BodyM color={ICAcolors.secondary}>
                        Copyright 2022. All rights reserved
                    </BodyM>
                </Box>
            </Box>
        </>
    );
}
