import { SupportedApplication } from "@liveart/nft-client/dist/whiteLabel";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { PALM_CHAIN_ID } from "@liveart/nft-client/dist/chainId";
import dynamic from "next/dynamic";
import { AppBarProps } from "@mui/material";
import { loadTokens } from "~/api/choreography";
import { useTokensList } from "~/features/artwork";
import { Homepage } from "~/features/ICA/homepage";
import { getTokensByApplicationsFromFirebase } from "~/BFF/BE";
import { Token } from "~/api/data-schema";
import { GTM } from "~/features/GTM";

const ICAHeader = dynamic<{
    position?: AppBarProps["position"];
}>(() => import("~/features/header/ica").then((mod) => mod.ICAHeader));

const tokenIds = ["1", "2", "3"];

export async function getStaticProps() {
    const tokens = await getTokensByApplicationsFromFirebase({
        applicationArray: [SupportedApplication.icamiami],
        networkId: String(PALM_CHAIN_ID),
        limit: -1,
        offset: -1,
        tokenIds: tokenIds.map((el) => parseInt(el, 10)),
        editionOf: -1,
    });

    return {
        props: {
            tokens,
        },
        revalidate: 10,
    };
}

export default function ICAHomepage({
    tokens: serverTokens = [],
}: {
    tokens: Token[];
}) {
    const dispatch = useDispatch();
    const clientTokens = useTokensList();
    const tokens = clientTokens?.length ? clientTokens : serverTokens;
    useEffect(() => {
        dispatch(
            loadTokens({
                tokenIds,
                limit: -1,
                offset: -1,
                applications: [SupportedApplication.icamiami],
                networkId: PALM_CHAIN_ID,
            }),
        );
    }, [dispatch]);

    const auctionEnd = new Date(1649878437104);

    return (
        <>
            <GTM trackingId="G-32YMQMVD71" />
            <Homepage
                auctionEnd={auctionEnd}
                tokens={tokens.length > 0 ? tokens : []}
            />
        </>
    );
}

ICAHomepage.headerWrapper = () => <ICAHeader />;
ICAHomepage.footerWrapper = () => <div />;
