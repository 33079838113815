import { Box, Button, Typography } from "@mui/material";
import { styled, Theme } from "@mui/system";
import { ArrowForward as ArrowForwardIcon } from "@mui/icons-material";
import Link from "next/link";
import { BodyM } from "~/features/typography";
import { useScreenOptions } from "~/features/screen";
import FirstImage from "./image1.png";
import SecondImage from "./image2.png";
import ThirdImage from "./image3.png";
import Elipse from "./elipse.svg";

const StyledImage = styled(
    ({ src, margin, ...other }: { src: string; margin?: number }) => (
        <Box {...other} />
    ),
)(
    ({
        theme,
        withMargin,
        src,
    }: {
        theme: Theme;
        withMargin?: boolean;
        src: string;
    }) => ({
        backgroundImage: `url(${src})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        width: 368,
        height: 368,
        minWidth: 368,
        minHeight: 368,
        marginLeft: withMargin ? "80px" : "0",
        [theme.breakpoints.down("xs")]: {
            width: 240,
            height: 240,
            minWidth: 240,
            minHeight: 240,
            marginRight: 0,
        },
    }),
) as React.ComponentType<{
    withMargin?: boolean;
    src: string;
}>;
const Images = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-evenly",
    marginTop: "144px",
    marginBottom: "120px",
    overflowX: "auto",
    [theme.breakpoints.down("md")]: {
        justifyContent: "flex-start",
        marginLeft: "1rem",
    },
}));
const Description = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-evenly",
    paddingRight: "1rem",
    paddingLeft: "1rem",
    [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
    },
}));
const Left = styled(Box)(({ theme }) => ({
    position: "relative",
    width: 558,
    margin: "auto",
    "& *": {
        zIndex: 2,
    },
    [theme.breakpoints.down("sm")]: {
        width: "90%",
    },
}));
const StyledElipse = styled(Elipse)({
    position: "absolute",
    top: "50%",
    right: "50%",
    transform: "translate(50%, -50%)",
    zIndex: 0,
});
const LinkWrapper = styled(Box)({
    textDecoration: "underline",
    textUnderlineOffset: "2px",
    marginLeft: "0.4rem",
});
const Right = styled(Box)(({ theme }) => ({
    width: 304,
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
        width: "90%",
        marginTop: "3rem",
    },
}));
const ButtonWrapper = styled(Box)(({ theme }) => ({
    marginTop: "2.5rem",
    [theme.breakpoints.down("sm")]: {
        textAlign: "center",
    },
}));

export function StayConnected() {
    const { isMobile } = useScreenOptions();

    return (
        <Box>
            <Images>
                <StyledImage src={FirstImage} />
                <StyledImage withMargin src={SecondImage} />
                <StyledImage withMargin src={ThirdImage} />
            </Images>
            <Description>
                <Box flex="1">
                    <Left>
                        <StyledElipse />
                        <Box position="relative">
                            <Typography variant="h5" color="secondary">
                                The 365 Party at ICA Miami includes a fabulous
                                evening of cocktails, dinner, and dancing in the
                                museum’s Sculpture Garden to honor Jorge
                                Gonzalez and celebrate the gift of free and open
                                access to contemporary art. The night will also
                                highlight ICA Miami’s teen programs and our work
                                to educate and inspire the next generation of
                                artists.
                            </Typography>
                        </Box>
                        <Box mt="2.5rem" position="relative">
                            <Typography variant="h5" color="secondary">
                                Tickets and tables are available for purchase
                                <LinkWrapper component="span">
                                    <Typography
                                        component="span"
                                        variant="h5"
                                        color="primary"
                                    >
                                        <Link href="https://365.icamiami.org/#tickets-and-tables">
                                            here
                                        </Link>
                                    </Typography>
                                </LinkWrapper>
                            </Typography>
                        </Box>
                    </Left>
                </Box>
                <Box flex="1">
                    <Right>
                        <Typography variant="h5" color="primary">
                            Sign up to receive auction updates and information,
                            including launch times and more!
                        </Typography>
                        <ButtonWrapper>
                            <Button
                                href="mailto:365party@icamiami.org"
                                fullWidth={isMobile}
                                size="large"
                                variant="contained"
                                color="primary"
                                endIcon={<ArrowForwardIcon htmlColor="white" />}
                            >
                                <BodyM color="white">STAY CONNECTED</BodyM>
                            </Button>
                        </ButtonWrapper>
                    </Right>
                </Box>
            </Description>
        </Box>
    );
}
