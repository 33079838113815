import { Box } from "@mui/material";
import { BodyM, H1 } from "~/features/typography";
import { fontFamilies } from "~/theme";
import { ICAcolors } from "../theme";

interface CountdownProps {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
}

export function Countdown({ days, hours, minutes, seconds }: CountdownProps) {
    return (
        <Box
            width={315}
            display="flex"
            justifyContent="space-evenly"
            fontFamily={fontFamilies.hurme2}
        >
            <Box display="flex" alignItems="baseline">
                <H1 strong color={ICAcolors.secondary}>
                    {days}
                </H1>
                <BodyM color={ICAcolors.secondary}>d</BodyM>
            </Box>
            <Box display="flex" alignItems="baseline">
                <H1 strong color={ICAcolors.secondary}>
                    {hours}
                </H1>
                <BodyM color={ICAcolors.secondary}>h</BodyM>
            </Box>
            <Box display="flex" alignItems="baseline">
                <H1 strong color={ICAcolors.secondary}>
                    {minutes}
                </H1>
                <BodyM color={ICAcolors.secondary}>m</BodyM>
            </Box>
            <Box display="flex" alignItems="baseline">
                <H1 strong color={ICAcolors.secondary}>
                    {seconds}
                </H1>
                <BodyM color={ICAcolors.secondary}>s</BodyM>
            </Box>
        </Box>
    );
}
