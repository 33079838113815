import { Avatar, Box, Button } from "@mui/material";
import { styled } from "@mui/system";
import { SupportedApplication } from "@liveart/nft-client/dist/whiteLabel";
import { PALM_CHAIN_ID } from "@liveart/nft-client/dist/chainId";
import { ArtworkMedia } from "~/features/artwork";
import { BodyM, BodyS, H5 } from "~/features/typography";
import { ICAcolors } from "../../theme";
import ArtistIcon from "../AboutArtist/artistNew.jpg";
import { Token } from "~/api/data-schema";
import { useTokenMarketDataFromToken } from "~/features/tokenMarketData";
import { EtherIcon } from "~/features/icon/EtherIcon";
import { formatPrice } from "~/features/formatters";
import { generatePDPLink } from "~/features/PDP/generatePDPLink";

const Container = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    width: 350,
    height: 500,
    [theme.breakpoints.down("md")]: {
        width: 307,
        height: 400,
    },
    [theme.breakpoints.down("sm")]: {
        width: 258,
    },
}));
const Media = styled(Box)({
    height: 384,
    overflow: "hidden",
});
const Divider = styled(Box)({
    height: 1,
    backgroundColor: ICAcolors.primary,
    width: "105%",
});
const StyledAvatar = styled(Avatar)({
    width: 20,
    height: 20,
});

interface ArtworkCardProps {
    token: Token;
}
export function ArtworkCard({ token }: ArtworkCardProps) {
    const {
        upcomingAuction,
        activeAuction,
        endedAuction,
        highestBidder,
        highestBid,
    } = useTokenMarketDataFromToken(token);

    return (
        <Container>
            <Media>
                <ArtworkMedia metadata={token.metadata} />
            </Media>
            <Box mt="1.5rem">
                <H5 color={ICAcolors.primary} strong>
                    {token.metadata.name}
                </H5>
            </Box>
            <Box mt="0.5rem" display="flex" alignItems="center">
                <StyledAvatar src={ArtistIcon} />{" "}
                <Box ml="5px">
                    <BodyM color={ICAcolors.secondary}>Cory Van Lew</BodyM>
                </Box>
            </Box>
            <Divider />
            <Box mt="5px">
                <H5 color={ICAcolors.secondary} strong>
                    {upcomingAuction && (
                        <>
                            Starting bid: <EtherIcon />
                            {formatPrice(highestBid)}
                        </>
                    )}
                    {activeAuction && highestBid && (
                        <>
                            Current bid: <EtherIcon />
                            {formatPrice(highestBid)}
                        </>
                    )}
                </H5>
            </Box>
            {highestBidder && !endedAuction && (
                <Box>
                    <BodyS color={ICAcolors.secondary}>
                        Highest bidder: {highestBidder}
                    </BodyS>
                </Box>
            )}
            {endedAuction && (
                <Box>
                    <BodyS color={ICAcolors.secondary}>
                        Winning bid: <EtherIcon />
                        {formatPrice(highestBid)}
                    </BodyS>
                </Box>
            )}
            <Box mt="20px">
                <Button
                    href={generatePDPLink({
                        tokenId: token.tokenId,
                        application: SupportedApplication.icamiami,
                        blockchainId: PALM_CHAIN_ID,
                        tokenContractAddress:
                            token.tokenContractAddress as string,
                        withBasePath: true,
                    })}
                    size="large"
                    fullWidth
                    variant="contained"
                    color="primary"
                >
                    <BodyM color={ICAcolors.textPrimary}>VIEW</BodyM>
                </Button>
            </Box>
        </Container>
    );
}
