import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { useScreenOptions } from "~/features/screen";
import { H1, H2 } from "~/features/typography";
import { ICAcolors } from "../../theme";
import ArtistImg from "./artistNew.jpg";

const Artist = styled(Box)(({ theme }) => ({
    position: "relative",
    display: "flex",
    background: ICAcolors.secondary,
    marginTop: "8rem",
    paddingTop: "10rem",
    paddingBottom: "12rem",
    [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: "8rem",
    },
}));

const OverflowContainer = styled(Box)(({ theme }) => ({
    overflow: "hidden",
    width: "140%",
    marginLeft: -30,
    display: "flex",
    position: "absolute",
    top: -25,
    "& h2, h1": {
        whiteSpace: "nowrap",
    },
    [theme.breakpoints.down("sm")]: {
        top: 0,
        width: "340%",
    },
}));

const Image = styled(Box)(({ theme }) => ({
    backgroundImage: `url(${ArtistImg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    minWidth: 512,
    minHeight: 637,
    width: 512,
    height: 637,
    marginBottom: "3rem",
    [theme.breakpoints.down("sm")]: {
        minWidth: 343,
        minHeight: 426,
        width: 343,
        height: 426,
    },
}));

const ArtistTextContainer = styled(Box)(({ theme }) => ({
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingLeft: "6rem",
    paddingRight: "6rem",
    [theme.breakpoints.down("sm")]: {
        paddingLeft: "1rem",
        paddingRight: "1rem",
    },
}));

const ArtistText = styled(Box)(({ theme }) => ({
    width: 542,
    [theme.breakpoints.down("lg")]: {
        width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
        width: "100%",
    },
}));

export function AboutArtist() {
    const { isMobile } = useScreenOptions();
    return (
        <Artist>
            <OverflowContainer>
                {[...Array(4)].map(() => (
                    <>
                        {isMobile ? (
                            <Box mr="2rem">
                                <H2 color="#ECE441">
                                    About the Artist + Auction
                                </H2>
                            </Box>
                        ) : (
                            <Box mr="4rem">
                                <H1 color="#ECE441">
                                    About the Artist + Auction
                                </H1>
                            </Box>
                        )}
                    </>
                ))}
            </OverflowContainer>
            <Box flex="1" display="flex" justifyContent="center">
                <Image />
            </Box>
            <ArtistTextContainer>
                <ArtistText>
                    <Box mb="3.5rem">
                        <Typography variant="h3" color="textPrimary">
                            Cory Van Lew is creating limited-edition NFTs for
                            the 365 Party
                        </Typography>
                    </Box>
                    <Box>
                        <Typography color="textPrimary" variant="h5">
                            Deeply impacted by the ongoing collective experience
                            of COVID19, visual artist Cory Van Lew has responded
                            to the crisis with a series of paintings and NFTs
                            featuring pandemic-related motifs— anonymous figures
                            in hazmat suits, their faces obscured by
                            masks—uncannily rendered in his signature washes of
                            warm pinks and blues. For the 365 Party, Van Lew
                            will organize an on-site painting performance
                            resulting in 4 unique paintings/NFT pairings, 3 of
                            which will be auctioned to benefit the museum, and 1
                            of which will enter the museum’s collection.
                        </Typography>
                        <br />
                        <Typography color="textPrimary" variant="h5">
                            In this first-ever performance, Van Lew will use
                            live figures in hazmat suits as subject-stencils,
                            directing them through the museum space and
                            rendering their forms with an irreverent process of
                            painterly gesture and chance. The resulting
                            paintings—indexical images that document a specific
                            moment in time and culture—will be minted as NFTs
                            and sold on ICA Miami’s dedicated blockchain
                            platform.
                        </Typography>
                        <br />
                        <Typography color="textPrimary" variant="h5">
                            Cory Van Lew (American, b. 1990; lives and works in
                            Miami) is a visual artist known for his spirit of
                            innovation. Since his entry into the NFT community,
                            Cory has risen as one of the prominent artists and
                            voices in the space, working with figures and brands
                            such as Mike Tyson, iHeart Radio, RTFKT Studios, and
                            Disney, and minting on platforms including SuperRare
                            and OneOf. He continues to develop a traditional
                            painterly practice and pursues performative
                            collaborations in tandem with his strong Web3
                            presence.
                        </Typography>
                    </Box>
                </ArtistText>
            </ArtistTextContainer>
        </Artist>
    );
}
